<template>
  <Loader :loading="isLoading">
    <div class="row">
      <div class="col-md-10 offset-md-1">
        <div class="kt-simple-page__content-wrap">
          <h1 class="kt-align-center kt-margin-b-30">Discord usernames</h1>

          <div class="table-responsive">
            <table class="table table-striped table-bordered table-hover" id="discord_table">
              <thead>
              <tr>
                <th nowrap>Username</th>
                <th nowrap>Seen?</th>
                <th nowrap>Action</th>
              </tr>
              </thead>
              <tbody v-if="discords">
              <tr v-for="discord in discords">
                <td class="kt-font-xl">{{ discord.discord }}</td>
                <td>
                  <template v-if="!discord.discord_seen">
                    <a href="#" @click.prevent="seenDiscord(discord.id)" class="btn btn-sm btn-secondary">Yes</a>
                  </template>
                  <template v-else>
                    Yes
                  </template>
                </td>
                <td><a href="#" @click.prevent="updateDiscord(discord.id)" class="btn btn-sm btn-warning">Approve</a></td>
              </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>
  </Loader>
</template>

<script>
import Loader from '../components/global/Loader';

export default {
  name: 'RiskDisclosure',
  components: {
    Loader
  },
  data () {
    return {
      isLoading: false,
      discords: []
    }
  },
  mounted() {
    this.loadDiscord();
  },
  methods: {
    updateDiscord (selected_id) {
      this.isLoading = true

      axios.patch(this.$api.discord + '/check', {id: selected_id})
        .then((response) => {
          this.$store.dispatch('showSuccessNotification', 'Success')
          this.loadDiscord();
        }).catch((error) => {
        this.isLoading = false
        if (error.response) {
          if (error.response.status === 400) {
            this.$store.dispatch('showErrorNotification', error.response.data.data.message)
          }else{
            this.$store.dispatch('showErrorNotification', 'Error!')
          }
        }
      })
    },
    seenDiscord (selected_id) {
      this.isLoading = true

      axios.patch(this.$api.discord + '/seen', {id: selected_id})
        .then((response) => {
          this.$store.dispatch('showSuccessNotification', 'Success')
          this.loadDiscord();
        }).catch((error) => {
        this.isLoading = false
        if (error.response) {
          if (error.response.status === 400) {
            this.$store.dispatch('showErrorNotification', error.response.data.data.message)
          }else{
            this.$store.dispatch('showErrorNotification', 'Error!')
          }
        }
      })
    },
    loadDiscord () {
      this.isLoading = true

      axios.get(this.$api.discord + '/list')
        .then((response) => {
          this.isLoading = false
          this.discords = response.data.data;
        }).catch((error) => {
          this.isLoading = false
          this.$store.dispatch('showErrorNotification', 'Error')
        })
    }
  },
}
</script>
