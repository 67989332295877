<template>
  <div class="kt-grid kt-grid--ver kt-grid--root kt-page">
    <div class="kt-grid kt-grid--hor kt-grid--root kt-simple-page">
      <div class="kt-grid__item kt-grid__item--fluid kt-grid">
        <div class="kt-grid__item kt-grid__item--fluid kt-simple-page__wrapper">
          <div class="kt-simple-page__container">
            <router-view :key="$route.fullPath" />
          </div>
        </div>
        <FooterComponent/>
      </div>
    </div>
    <Notification/>
  </div>
</template>

<script>
  import Notification from '../components/global/Notification'
  import FooterComponent from '../components/functional/FooterComponent'
  import {siteName} from "@/config/api"

  export default {
    name: 'AuditPage',
    components: {
      Notification,
      FooterComponent
    },
    data() {
      return {
        sitename: siteName
      }
    }
  }
</script>

<style>
  p {
    font-size: 1.15rem;
  }
  p ul li, p ol li {
    font-size: 1.15rem;
  }
  ul li, ol li {
    font-size: 1.15rem;
  }
  p a {
    white-space: pre-wrap;
  }
  .table {
    font-size: 1.15rem;
    color: #646c9a;
  }
</style>
