<template>

  <div class="kt-grid kt-grid--ver kt-grid--root kt-page">
    <div class="kt-grid__item kt-grid__item--fluid kt-grid  kt-error-v1" style="background-image: url(/assets/errors/error-bg1.jpg);">
      <div class="kt-error-v1__container">
        <h1 class="kt-error-v1__number">Global Calculation is in Progress</h1>
        <p class="kt-error-v1__desc">
          Please, wait several hours until global calculation is in progress.<br/>
          Once it's over - you would be able to <router-link :to="{ name: 'login' }">login again</router-link>.
        </p>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'ProfitCreditingPage',
  components: {},
  data () {
    return {}
  },
  computed: {},
  created () {

  },
  methods: {

  }
}
</script>

<style lang="scss">
.kt-error-v1 {
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;

  .kt-error-v1__container {
    .kt-error-v1__number {
      font-size: 30px;
      margin-left: 40px;
      margin-top: 3rem;
      font-weight: 700;
      color: #595d6e;
    }

    .kt-error-v1__desc {
      font-size: 1.2rem;
      margin-left: 40px;
      color: #74788d;
    }
  }
}

@media (max-width: 768px) {

  .kt-error-v1 .kt-error-v1__container .kt-error-v1__number {
    margin: 20px 0 1rem 2rem;
    font-size: 1.7rem;
  }

  .kt-error-v1 .kt-error-v1__container .kt-error-v1__desc {
    margin-left: 2rem;
    padding-right: 0.5rem;
    font-size: 0.9rem;
  }

}
</style>
