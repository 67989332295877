<template>
  <div class="row">
    <div class="col-md-10 offset-md-1">
      <div class="kt-simple-page__content-wrap">
        <h1 class="kt-align-center kt-margin-b-30">YieldNodes: An Assessment of Risk</h1>
        <div class="kt-align-center kt-margin-b-30">Updated 30.04.2022 (Updates added with yellow marker, no changes to original for continuity)</div>
        <p>As we get a lot of enquiries, we wanted to provide further clarity regarding what YieldNodes is (and what it isn't), and how risky the participation is, but please realise that this assessment is somewhat biased so research and do your own due diligence before deciding to participate.</p>

        <p><b>Note: this is *not* a financial advice</b> - we are not financial advisors, we are simply providing some insight into our operation and thought processes to help you make a more informed decision.</p>

        <h3>YieldNodes definitely has to be classified as a high-risk participation model.</h3>

        <p>It has evolved from its beginnings as a pure masternoding project to a masternoding / staking hybrid that grew through the aquisition of coins / partnerships and became more akin to a start-up in the process - generating additional revenue from a lot of sub-projects and use cases that are being developed as we speak.</p>

        <p>Everything generated is thrown into the pot, and disbursed every month on the basis of 87.5% for participants and 12.5% for Yieldnodes.</p>

        <p>This is carefully assessed as coins fluctuate in value to some extreme degree... sometime upwards by 40%, only to crash 50% in small volatile projects. So, when we do the assessment, we take into account profits from projects, fees / gains from exchanges, voucher sales, ad revenue, and many other streams as we grow and enter different markets - hopefully getting things right (or as right as humanly possible). This doesn't mean that "playing it safe" at times means "lost" profits for members.</p>

        <p>Remember the Pot Scenario. We only profit 15% of what leaves the system... so it would be better for us to pay out as much as we can and fill our pockets. However, paying out a smaller amount is safer as it strenghtens the project... and getting that part right is the art :)</p>

        <p>If we're in a good position funds-wise, there can be also some out-of-the-ordinary months (we had a 19.2% month recently), so it will all eventually come back to our participants who remain our highest priority.</p>

        <p><b>Important:</b><br>
          As much as you are lead to believe, through marketing or hype, <b>MONEY IS NOT GENERATED OUT OF THIN AIR</b>.</p>

        <p>If something goes, something else has to give.</p>

        <p>We're in a unique position right now - a seismic shift of value from the FIAT System into cryptocurrencies is occurring and, while this continues, you can make previously unimaginable gains. As capital flows and ecosystems emerge from the outdated, shady, and inherently corrupt FIAT monetary system into accountable, transparent and visible blockchain technology, early adopters can profit handsomely from this shift as huge amounts are being moved at this stage of the evolution.</p>

        <p>But... it's still a highly-experimental and risky adventure, and we know of no-one else who has attempted a similar project of such huge scale.</p>

        <p>Profits are currently large, but will eventually shrink (they have to) as systems get adopted and made sustainable with use cases that turn regular profits from sales / memberships fees,  usage, etc. - it's like being in front of a tidal wave... more waves will follow, but of lower magnitude.</p>

        <p>We saw that agressive acquisition was the way to move forward as, right now, the high returns that some find unbelievable for a legitimate program go hand in hand with growth (I have to thank Urs for this - his foreseight into aquisition and expansion have made YieldNodes possible).</p>

        <p>He is not only a great Tech Leader, but also constructed the vision of YieldNodes / Decenomy - putting key players into place as we speak to ensure the sustainable growth and the transformations that need to take place to keep us moving in the right direction.</p>

        <p>YieldNodes is NOT MLM or multi-level marketing... the affiliate program offers 5% of deposits as an incentive to grow our reach, but we are NOT dependant on new members and distance ourselves from such unsustainable practices.</p>

        <p>YieldNodes is very dependant on the Cryptomarket, and correlates with Bitcoin strongly. We manage to survive bear markets by carefully planning ahead and holding reserves, as well as working overtime to strenghten our projects in crisis times to have them soar during bullish times.</p>

        <h2>Questions often Asked:</h2>

        <h3>Is there an offficial YieldNodes Forum / Discussion Group?</h3>

        <p>Not officially, but groups do have formed. We give members free reign if they want to communicate, but we do not plan to open a forum because we do not need excessive growth or hype. Forums often morph into breeding grounds for either hype or hate and suspicion. They're also time-consuming, and need constant managing / monitoring, so we prefer to focus on the task at hand - producing the returns.</p>

        <p><span style="background-color:yellow;">Update: While we are not in charge we like the forum on Facebook under this URL lead by 3rd party YieldNodes users: <a href="https://www.facebook.com/groups/communitygroupyieldnodes/" target="_blank">https://www.facebook.com/groups/communitygroupyieldnodes/</a></span></p>

        <h3>Will these returns keep going?</h3>

        <p>The returns will have to scale back at some point, but it's impossible to estimate when that might be. The main priority is security of funds and sustainability. After all, when you withdraw funds, you want to have confidence that the numbers are there. So, growth will slow down as more ecosystems get adopted. This might take years or months, but we'll always strive to make sure it's sustainable and fact-based.</p>

        <h3>Are you regulated / audited?</h3>

        <p>We aren't, but we explore regulation / licensing as YieldNodes is hard to categorize. Some transformations will eventually take place, but the goal is to be regulated.</p>


        <h3>Why are you not hiring an independent auditor?</h3>

        <p>We enquired, but since we work blockchain only, there is not much choice. YieldNodes doesn't hold company bank accounts as we only work in the new economy, and auditors shy away from what they don't understand. We did a members-based audit as this is ultimately whom we feel we should appease (members, not prospects) in February 2021, and we plan to have one more in October 2021 to show the feasibility and generation of returns reflected in account balances. By choosing a diverse range of memebrs who are not affiliated with each other, we feel it makes for quite a good representation of our client-base.</p>

        <p>While this might appear biased versus hiring an outside party, YieldNodes is not a multi-level referral program, so there is little incentive for members to misrepresent or misread the program.</p>

        <h3>I did a compounding calculation and my 10k will be 1 million in 5 years. Will I be rich?</h3>

        <p>As mentioned, the returns will eventually reduce so, while you cannot exclude the possibility, it's highly probable that you won't see what that fancy compound calculator spits out. However, we pledge to do our very best, but clearly cannot promise or guarantee any scenarios to materialise. Situations shift a lot and, as mentioned countless times before, we're very experimental in what we do, and there is no blueprint to follow that guarantees success, so do NOT participate with funds you cannot afford to lose!</p>

        <h3>I'm in doubt - what should I do?</h3>

        <p>If you're in doubt as to whether you should join, don't do it. Ultimately, you need to be able to sleep at night and not be constantly worried about your participation. Alternatively, you can plant a seed - if 500 Euros is not going to break your bank then try for a while and see how it grows. That way, you participate and don't miss out, but if things go awry, the pain won't be so bad.</p>

        <h3>What is your personal stake in YieldNodes? Are you "all in"?</h3>

        <p>This is a very personal question, but for the sake of transparency I will gladly answer. As the person occupying the CEO position in YieldNodes, I have more insight (obviously), but I'm also "only" invested roughly 25% with my personal liquid funds as I also like to hold Bitcoin, Ethereum and other promising coins.</p>

        <h3>You are a scam! I saw some articles that reviewed you!</h3>

        <p>Everyone is entitled to their opinion, and if you believe such articles then it's your right to do so. However, if you read negative articles about YieldNodes, the chances are that you'll find a different program being reccommended by the authors ...and a referral link pointing to it. The idea behind this technique is to grab people looking for YieldNodes reviews and "redirect" them to actual scams or ponzi schemes that promise very big returns to the affiliates (reviewers).</p>

        <h3>What happens if the market collapses? How are we protected (are we even protected)? How will you handle such a situation? Can I lose everything?</h3>

        <p>Unfortunately there is no way to sugarcoat this, nor should it be. The chance of losing everything is there - albeit it a very slim chance. If the markets collapse then we would really need to look at all factors, but we are confident that at least a portion of the funds (or the seed money) can be saved. However, we cannot guarantee it. I can only state that we have different protocols laid out that dictate how we respond to such a situation and, at least in the past, we managed to respond well to market down-turns!</p>

        <h3>So, are my profits at risk, or is my capital as well?</h3>

        <p>Unfortunately both, so tread carefully!</p>

        <p><span style="background-color:yellow;">Update: We cannot stress this enough and it warrants an Update. Do not LOAN on Assets you own and do not use money that is vital to your well being ! We want to be a force of good and to that means we need to be ethical about what YieldNodes is and what it is not. The returns we generate are only possible by exposing ourselves to a high degree of risk. So be careful and consider YieldNodes a part of a diversified portfolio in the high risk category!</span></p>


        <h2>Now, to reiterate YieldNodes in a few words:</h2>

        <p><b>Risky, Honest, Not MLM, Experimental</b></p>

        <p>I hope I've been able to provide some useful guidelines to help you assess us better.</p>

        <p>We strongly encourage people to do their own due diligence - read reviews, check the projects we're involved with (the coins, the projects as displayed on yieldnodes.com), etc. and then decide for themselves what their course of action should be.</p>

        <p>From a personal standpoint, I pledge to work honestly, as transparently as possible, and to devote all my time and energy to the community :)</p>

        <p>We did well over the past 2 years (almost ... we celebrate in October), but the past is only an indicator, not a promise of the future.</p>

        <p><span style="background-color:yellow;">Update: YieldNodes started in October 2019. So we will actually celebrate in October our 3 year anniversary !</span></p>

        <p><span style="background-color:yellow;">Thank you to all the members the words of encouragement, the friendliness and support and godwill .. it made a big big difference ! Thanks to everyone whos working to make Decenomy a reality!</span></p>

        <p>Sincere wishes,<br>
          <br>
          Steve<br>
          CMO<br>
          YieldNodes.com</p>
        <div class="kt-align-center kt-font-bold kt-margin-t-30"><a href="/">Back To Homepage</a></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RiskDisclosure',
  components: {},
  data () {
    return {}
  }
}
</script>
