<template>
  <div class="row">
    <div class="col-md-10 offset-md-1">
      <div class="kt-simple-page__content-wrap">
        <h1 class="kt-align-center kt-margin-b-30">Terms and Conditions</h1>
        <h2>General</h2>

        <p>Please read the following terms and conditions (“Terms of Use”) which govern your use of this
          Yieldnodes website (the “Site”). By using the Site(s), you knowingly and willingly agree to be
          bound by these Terms of Use, which may be updated from time to time and posted on this Terms of
          Use page.</p>
        <h2>Purpose; No Advice</h2>

        <p>This Site is intended to provide an overview of Yieldnodes and is for general
          information purposes only. The Site is not intended to provide investing, accounting, tax, legal or
          any other advice, nor shall any content on this Site be deemed a recommendation that you pursue
          any investment style or strategy. If you would like investing, accounting, tax, legal or any other
          advice, you should consult with your own professional advisors regarding your individual
          circumstances and needs.</p>

        <h2>No Offer</h2>

        <p>Nothing on this Site is intended to constitute an offer to sell or a solicitation of any offer to any
          securities or other assets, nor should it or any part of it be relied upon in connection with any offer
          or sale of securities or other assets. Any such offer or solicitation can and will be made only by
          means of a complete offering memorandum and related subscription materials which contain
          significant additional information about the terms of an investment, only in jurisdictions in which
          such an offer would be lawful, and only to individuals who meet the investor sophistication criteria
          that are determined from time to time by Yieldnodes in its sole and absolute discretion (such
          documents, the “Offering Documents”). Any decision to invest must be based solely upon the
          information set forth in the Offering Documents, regardless of any information that may have been
          otherwise furnished, including this Site.</p>

        <h2>Other Disclaimers</h2>

        <p>This Site and the information contained herein are provided “as is” and Yieldnodes and its affiliates
          expressly disclaim all express or implied warranties and representations with respect to the fitness
          of information contained herein for any particular usage, its merchantability or its application or
          purpose.</p>

        <p>Without limiting the foregoing, Yieldnodes does not make any representation or warranty as to the
          accuracy, adequacy, or completeness of the information and materials contained on this Site or that
          the Site will operate without interruption or error. In no event shall Yieldnodes or its affiliates be
          responsible or liable for the correctness of any such material or damage or lost opportunities
          resulting from use of this data.</p>

        <p>You acknowledge that by using the Site you may be subject to privacy and security exploitations,
          including without limitation, the ability of unauthorized persons to intercept or access information
          transmitted by you through the Site.</p>

        <h2>Intellectual Property</h2>

        <p>Except as otherwise noted, all content and materials on the Site are the property of Yieldnodes and
          should not be used, modified or reproduced without Yieldnodes’s written consent. Yieldnodes, and the
          proprietary logos and marks included on the Site that identify Yieldnodes services and products are
          registered trademarks in the United States and other countries and may not be used without the
          prior written consent of Yieldnodes.</p>

        <h2>Additional Provision for European Users</h2>

        <p>If you are domiciled in or have a registered office in a member state of the European Economic Area
          (“EEA”) that has implemented the Alternative Investment Fund Managers Directive (Directive
          2011/61/EU) in a manner that may impose disclosure, reporting or other obligations on Yieldnodes or
          its affiliates, you expressly agree and acknowledge that you or any other person or entity you
          represent initiated the discussion, correspondence or other communications with Yieldnodes or its
          affiliates or agents, and any information you received regarding any investment funds managed by
          Yieldnodes was pursuant to your request; and none of Yieldnodes or its affiliates or agents at any time
          directly or indirectly contacted you with respect to the provision of investment services or
          investment in any investment fund managed by Yieldnodes or its affiliates prior to such unsolicited
          initiation of discussions, correspondence or other communications.</p>
        <div class="kt-align-center kt-font-bold kt-margin-t-30"><a href="/">Back To Homepage</a></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TermsAndConditions',
  components: {},
  data () {
    return {}
  }
}
</script>
