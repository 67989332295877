<template>
  <div class="row">
    <div class="col-md-10 offset-md-1">
      <div class="kt-simple-page__content-wrap">
        <h1 class="kt-align-center kt-margin-b-30">Privacy Policy</h1>
        <p>Effective date: January 01, 2020</p>

        <p>EXCEPTIONAL MEDIA LIMITED (&quot;us&quot;, &quot;we&quot;, &quot;our&quot;
          “Yieldnodes” or “Yieldnodes Fund”) operates the http://www.yieldnodes.com website (hereinafter referred to as the &quot;Service&quot;).
          This page informs you of our policies regarding the collection, use and disclosure
          of personal data when you use our Service and the choices you have associated
          with that data.</p>

        <p>We use your data to provide and improve the Service. By using the Service, you
          agree to the collection and use of information in accordance with this policy.
          Unless otherwise defined in this Privacy Policy, the terms used in this Privacy
          Policy have the same meanings as in our Terms and Conditions, accessible from
          http://www.yieldnodes.com</p>

        <h2>Definitions</h2>

        <h4>Service</h4>
        <p>Service is the http://www.yieldnodes.com  website operated by
          EXCEPTIONAL MEDIA LIMITED</p>

        <h4>Personal Data</h4>
        <p>Personal Data means data about a living individual who can be identified from
          those data (or from those and other information either in our possession or likely to
          come into our possession).</p>
        <h4>Usage Data</h4>
        <p>Usage Data is data collected automatically either generated by the use of the
          Service or from the Service infrastructure itself (for example, the duration of a
          page visit).</p>
        <h4>Cookies</h4>
        <p>Cookies are small files stored on your device (computer or mobile device).</p>

        <h4>Data Controller</h4>
        <p>Data Controller means the natural or legal person who (either alone or jointly or in
          common with other persons) determines the purposes for which and the manner in
          which any personal information are, or are to be, processed.<br>
          For the purpose of this Privacy Policy, we are a Data Controller of your Personal
          Data.</p>
        <h4>Data Processors (or Service Providers)</h4>
        <p>Data Processor (or Service Provider) means any natural or legal person who
          processes the data on behalf of the Data Controller.</p>
        <p>We may use the services of various Service Providers in order to process your data
          more effectively.</p>
        <h4>Data Subject (or User)</h4>
        <p>Data Subject is any living individual who is using our Service and is the subject of
          Personal Data.</p>

        <h2>Information Collection and Use</h2>
        <p>We collect several different types of information for various purposes to provide
          and improve our Service to you.</p>

        <h3>Types of Data Collected</h3>

        <h4>Personal Data</h4>

        <p>While using our Service, we may ask you to provide us with certain personally
          identifiable information that can be used to contact or identify you (&quot;Personal
          Data&quot;). Personally identifiable information may include, but is not limited to:</p>
        <ul>
          <li>Email address</li>
          <li>First name and last name</li>
          <li>Phone number</li>
          <li>Address, State, Province, ZIP/Postal code, City</li>
          <li>Cookies and Usage Data</li>
        </ul>

        <p>We may use your Personal Data to contact you with newsletters, marketing or
          promotional materials and other information that may be of interest to you. You
          may opt out of receiving any, or all, of these communications from us by following
          the unsubscribe link or the instructions provided in any email we send.</p>

        <h3>When You Provide Information to Build Your Profile</h3>

        <p>Once you have created a Yieldnodes account, we will ask you to provide additional
          information about yourself to complete your “Profile”. The information we ask for
          may include your date of birth, place of birth, addresses, nationality, national
          insurance number (or other government issued identification number), citizenship
          and residency status, Tax ID, and information about your source of income,
          investment aims and appetite, occupation, employer and employment position,
          annual income, investment portfolio, total cash and liquid assets and other details
          or questions as may be required in the Profile creation and process may be
          amended by us from time to time.</p>
        <p>We will also ask you to provide a copy of an identification document, such as a
          passport, residency, visa or national identity card, a utility bill and such other
          documents as may be required by us in order to comply with our regulatory
          obligations and to verify your identity. Further details about the identification
          process can be found in the relevant Yieldnodes Fund terms and conditions. We will
          use the information you provide in your Profile to:</p>
        <ul>
          <li>verify your identity and carry out checks that we are required to conduct by
            applicable laws</li>
          and regulations, including without limitation, “know your customer”, anti-money
          laundering, fraud, sanctions and politically exposed person checks;</li>
          <li>contact you on matters related to your account, including, to request any
            additional information or documentation;</li>
          <li>provide you with notices related to your account, general updates, market
            updates and other marketing materials, including, the Services offered by members
            of the Yieldnodes Group</li>
          <li>tailor the products and services offered through the Site to you, including
            without limitation, to perform any suitability or appropriateness assessments for
            using our services and/or products;</li>
          <li>maintain administrative records relating to our business; and</li>
          <li>set up security measures to secure your account, including without limitation, to
            carry out two-factor authentication.</li>
        </ul>
        <p>You can update your Profile at any time by visiting the “My Account” page in the
          Settings menu. You further agree to update such information upon Yieldnodes’s
          request, if Yieldnodes considers the information provided as untrue, incorrect,
          incomplete and/or inconsistent with other information provided by you at any time.
          You acknowledge that we may rely upon such information and that you are
          responsible for any damages or losses which may result from any inaccuracies,
          including without limitation, the inappropriateness of our Services to your profile.</p>

        <h3>Usage Data</h3>

        <p>We may also collect information on how the Service is accessed and used (&quot;Usage
          Data&quot;). This Usage Data may include information such as your computer&#39;s Internet
          Protocol address (e.g. IP address), browser type, browser version, the pages of our
          Service that you visit, the time and date of your visit, the time spent on those
          pages, unique device identifiers and other diagnostic data.</p>

        <h3>Tracking &amp; Cookies Data</h3>
        <p>We use cookies and similar tracking technologies to track the activity on our
          Service and we hold certain information.</p>
        <p>Cookies are files with a small amount of data which may include an anonymous
          unique identifier. Cookies are sent to your browser from a website and stored on
          your device. Other tracking technologies are also used such as beacons, tags and
          scripts to collect and track information and to improve and analyse our Service.
          You can instruct your browser to refuse all cookies or to indicate when a cookie is
          being sent. However, if you do not accept cookies, you may not be able to use
          some portions of our Service.</p>

        <p>Examples of Cookies we use:</p>
        <ul>
          <li>Session Cookies. We use Session Cookies to operate our Service.</li>
          <li>Preference Cookies. We use Preference Cookies to remember your
            preferences and various settings.</li>
          <li>Security Cookies. We use Security Cookies for security purposes.</li>
        </ul>

        <h2>Use of Data</h2>

        <p>EXCEPTIONAL MEDIA LIMITED uses the collected
          data for various purposes:</p>

        <ul>
          <li>To provide and maintain our Service</li>
          <li>To notify you about changes to our Service</li>
          <li>To allow you to participate in interactive features of our Service when you
            choose to do so</li>
          <li>To provide customer support</li>
          <li>To gather analysis or valuable information so that we can improve our
            Service</li>
          <li>To monitor the usage of our Service</li>
          <li>To detect, prevent and address technical issues</li>
          <li>To provide you with news, special offers and general information about
            other goods, services and events which we offer that are similar to those that
            you have already purchased or enquired about unless you have opted not to
            receive such information</li>
        </ul>

        <h2>Legal Basis for Processing Personal Data under the
          General Data Protection Regulation (GDPR)</h2>

        <p>If you are from the European Economic Area (EEA), EXCEPTIONAL MEDIA HK LIMITED legal basis for collecting and using the
          personal information described in this Privacy Policy depends on the Personal Data
          we collect and the specific context in which we collect it.</p>

        <p>EXCEPTIONAL MEDIA LIMITED may process your
          Personal Data because:</p>
        <ul>
          <li>We need to perform a contract with you</li>
          <li>You have given us permission to do so</li>
          <li>The processing is in our legitimate interests and it is not overridden by your
            rights</li>
          <li>To comply with the law</li>
        </ul>

        <h2>Retention of Data</h2>

        <p>EXCEPTIONAL MEDIA LIMITED will retain your
          Personal Data only for as long as is necessary for the purposes set out in this
          Privacy Policy. We will retain and use your Personal Data to the extent necessary
          to comply with our legal obligations (for example, if we are required to retain your
          data to comply with applicable laws), resolve disputes and enforce our legal
          agreements and policies.</p>

        <p>EXCEPTIONAL MEDIA LIMITED will also retain
          Usage Data for internal analysis purposes. Usage Data is generally retained for a
          shorter period of time, except when this data is used to strenghten the security or to
          improve the functionality of our Service, or we are legally obligated to retain this
          data for longer periods.</p>

        <h2>Transfer of Data</h2>

        <p>Your information, including Personal Data, may be transferred to — and
          maintained on — computers located outside of your state, province, country or
          other governmental jurisdiction where the data protection laws may differ from
          those of your jurisdiction.</p>
        <p>If you are located outside Hong Kong and choose to provide information
          to us, please note that we transfer the data, including Personal Data, to Hong Kong and process it there.</p>
        <p>Your consent to this Privacy Policy followed by your submission of such
          information represents your agreement to that transfer.</p>
        <p>EXCEPTIONAL MEDIA LIMITED will take all the
          steps reasonably necessary to ensure that your data is treated securely and in
          accordance with this Privacy Policy and no transfer of your Personal Data will take
          place to an organisation or a country unless there are adequate controls in place
          including the security of your data and other personal information.</p>

        <h2>Disclosure of Data</h2>

        <h3>Legal Requirements</h3>

        <p>EXCEPTIONAL MEDIA LIMITED may disclose your
          Personal Data in the good faith belief that such action is necessary to:</p>
        <ul>
          <li>To comply with a legal obligation</li>
          <li>To protect and defend the rights or property of EXCEPTIONAL MEDIA HK LIMITED</li>
          <li>To prevent or investigate possible wrongdoing in connection with the
            Service</li>
          <li>To protect the personal safety of users of the Service or the public</li>
          <li>To protect against legal liability</li>
        </ul>

        <h2>Security of Data</h2>

        <p>The security of your data is important to us but remember that no method of
          transmission over the Internet or method of electronic storage is 100% secure.
          While we strive to use commercially acceptable means to protect your Personal
          Data, we cannot guarantee its absolute security.</p>

        <h2>Your Data Protection Rights under the General Data
          Protection Regulation (GDPR)</h2>

        <p>If you are a resident of the European Economic Area (EEA), you have certain data
          protection rights. EXCEPTIONAL MEDIA LIMITED
          aims to take reasonable steps to allow you to correct, amend, delete or limit the use
          of your Personal Data.</p>

        <p>If you wish to be informed about what Personal Data we hold about you and if you
          want it to be removed from our systems, please contact us.</p>

        <p>In certain circumstances, you have the following data protection rights:</p>

        <ul>
          <li>The right to access, update or delete the information we have on
            you. Whenever made possible, you can access, update or request deletion of
            your Personal Data directly within your account settings section. If you are
            unable to perform these actions yourself, please contact us to assist you.</li>
          <li>The right of rectification. You have the right to have your information
            rectified if that information is inaccurate or incomplete.</li>
          <li>The right to object. You have the right to object to our processing of your
            Personal Data.</li>
          <li>The right of restriction. You have the right to request that we restrict the
            processing of your personal information.</li>
          <li>The right to data portability. You have the right to be provided with a
            copy of the information we have on you in a structured, machine-readable
            and commonly used format.</li>
          <li>The right to withdraw consent. You also have the right to withdraw your
            consent at any time where EXCEPTIONAL MEDIA LIMITED relied on your consent to process your personal
            information.</li>
        </ul>
        <p>Please note that we may ask you to verify your identity before responding to such
          requests.</p>
        <p>You have the right to complain to a Data Protection Authority about our collection
          and use of your Personal Data. For more information, please contact your local
          data protection authority in the European Economic Area (EEA).</p>

        <h2>Service Providers</h2>

        <p>We may employ third party companies and individuals to facilitate our Service
          (&quot;Service Providers&quot;), provide the Service on our behalf, perform Service-related
          services or assist us in analysing how our Service is used.</p>

        <p>These third parties have access to your Personal Data only to perform these tasks
          on our behalf and are obligated not to disclose or use it for any other purpose.</p>

        <h3>Analytics</h3>

        <p>We may use third-party Service Providers to monitor and analyse the use of our
          Service.</p>

        <h4>Clicky</h4>

        <p>Clicky is a web analytics service. Read the Privacy Policy for Clicky
          here: https://clicky.com/terms</p>

        <h3>Behavioral Remarketing</h3>

        <p>EXCEPTIONAL MEDIA LIMITED uses remarketing
          services to advertise on third party websites to you after you visited our Service.
          We and our third-party vendors use cookies to inform, optimise and serve ads
          based on your past visits to our Service.</p>

        <h4>Facebook</h4>
        <p>Facebook remarketing service is provided by Facebook Inc.</p>
        <p>You can learn more about interest-based advertising from Facebook by
          visiting this page: https://www.facebook.com/help/164968693837950</p>
        <p>To opt-out from Facebook&#39;s interest-based ads, follow these instructions
          from Facebook: https://www.facebook.com/help/568137493302217</p>
        <p>Facebook adheres to the Self-Regulatory Principles for Online Behavioural
          Advertising established by the Digital Advertising Alliance. You can also
          opt-out from Facebook and other participating companies through the
          Digital Advertising Alliance in the USA http://www.aboutads.info/choices/,
          the Digital Advertising Alliance of Canada in
          Canada http://youradchoices.ca/ or the European Interactive Digital
          Advertising Alliance in Europe http://www.youronlinechoices.eu/, or opt-
          out using your mobile device settings.</p>
        <p>For more information on the privacy practices of Facebook, please visit
          Facebook&#39;s Data Policy: https://www.facebook.com/privacy/explanation</p>

        <h2>Links to Other Sites</h2>

        <p>Our Service may contain links to other sites that are not operated by us. If you
          click a third party link, you will be directed to that third party&#39;s site. We strongly
          advise you to review the Privacy Policy of every site you visit.</p>
        <p>We have no control over and assume no responsibility for the content, privacy
          policies or practices of any third party sites or services.</p>

        <h2>Children&#39;s Privacy</h2>

        <p>Our Service does not address anyone under the age of 18 (&quot;Children&quot;).
          We do not knowingly collect personally identifiable information from anyone
          under the age of 18. If you are a parent or guardian and you are aware that your
          Child has provided us with Personal Data, please contact us. If we become aware
          that we have collected Personal Data from children without verification of parental
          consent, we take steps to remove that information from our servers.</p>

        <h2>Changes to This Privacy Policy</h2>

        <p>We may update our Privacy Policy from time to time. We will notify you of any
          changes by posting the new Privacy Policy on this page.</p>
        <p>We will let you know via email and/or a prominent notice on our Service, prior to
          the change becoming effective and update the &quot;effective date&quot; at the top of this
          Privacy Policy.</p>
        <p>You are advised to review this Privacy Policy periodically for any changes.
          Changes to this Privacy Policy are effective when they are posted on this page.</p>

        <div v-html="$t('general.privacy_contact')"></div>

        <div class="kt-align-center kt-font-bold kt-margin-t-30"><a href="/">{{ $t('general.home_back') }}</a></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
  components: {},
  data () {
    return {}
  }
}
</script>
