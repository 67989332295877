<template>
  <!-- begin:: Page -->
  <div class="kt-grid kt-grid--ver kt-grid--root kt-page">
    <div class="kt-grid__item kt-grid__item--fluid kt-grid  kt-error-v1" style="background-image: url(assets/media/error/bg1.jpg);">
      <div class="kt-error-v1__container">
        <h1 class="kt-error-v1__number text-center kt-margin-t-50">Page Not Found</h1>
        <p class="kt-error-v1__desc text-center">
          This page doesn't exist! Go back or return to <router-link :to="{name: 'login'}">homepage</router-link>.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'NotFoundPage'
  }
</script>
