<template>
  <div class="row">
    <div class="col-md-8 offset-md-2">
      <div class="kt-simple-page__content-wrap">
        <div class="kt-portlet kt-portlet--mobile">
          <template v-if="showSuccess">
            <div class="kt-portlet__body">
              <div class="alert alert-success fade show" role="alert">
                <div class="alert-icon"><i class="far fa-check-square"></i></div>
                <div class="alert-text">You have been added to the list!</div>
              </div>
            </div>
          </template>
          <template v-else>
            <form class="kt-form kt-form--label-right" @submit.prevent="submitForm">
              <Loader
                :loading="isLoading"
              >
                <div class="kt-portlet__body">
                  <div class="kt-section kt-section--last">
                    <div class="kt-section__body">
                      <div class="kt-margin-b-20">
                        <p>YieldNodes plans to open up deeper partnership and regulated offerings for Institutional Investors. If you qualify or plan to be qualified sign up with your Name/Email and Phone number below.</p>
                        <p>As soon as we are ready we will get in touch and let you know what opportunities open up to work together closely !</p>
                        <p>We will not send any spam or non related materials and keep your contact information strictly private.</p>
                      </div>
                      <div class="form-group row">
                        <label class="col-md-4 col-form-label">Your Name:</label>
                        <div class="col-md-8">
                          <AccountInput
                            id="name"
                            v-model="formData.name"
                            type="text"
                            name="name"
                            :errors="errors"
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-md-4 col-form-label">Email address:</label>
                        <div class="col-md-8">
                          <AccountInput
                            id="email"
                            v-model="formData.email"
                            type="email"
                            name="email"
                            :errors="errors"
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-md-4 col-form-label">Phone number:</label>
                        <div class="col-md-8">
                          <AccountInput
                            ref="phoneInput"
                            id="phone"
                            v-model="formData.phone"
                            type="phone"
                            name="phone"
                            :errors="errors"
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="kt-form__actions">
                    <div class="kt-align-center">
                      <button type="submit" class="btn btn-success">Get Me on the List</button>
                    </div>
                  </div>
                </div>
              </Loader>
            </form>
          </template>
        </div>

        <div class="kt-align-center kt-font-bold kt-margin-t-30"><a href="/">Back To Homepage</a></div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/global/Loader";
import AccountInput from "@/components/forms/AccountInput";
import formHasErrors from "@/mixins/formHasErrors";
import requiresMfa from "@/mixins/requiresMfa";

export default {
  name: 'SubscribeII',
  components: {
    Loader,
    AccountInput
  },
  mixins: [
    formHasErrors,
    requiresMfa
  ],
  data () {
    return {
      isLoading: false,
      formData: {
        name: null,
        email: null,
        phone: null,
        phone_country: null
      },
      showSuccess: false

    }
  },
  watch: {
    'formData.phone' (val) {
      if (val && this.$refs.phoneInput && this.$refs.phoneInput.countryCode()) {
        this.formData.phone_country = this.$refs.phoneInput.countryCode().toUpperCase()
      }
    }
  },
  methods: {
    submitForm () {
      this.isLoading = true
      this.clearErrors()

      axios.post(this.$api.subscribe_ii, { ...this.formData })
        .then((response) => {
          this.isLoading = false
          if (response.status >= 200 && response.status < 300) {
            this.showSuccess = true;
            this.$store.dispatch('showSuccessNotification', 'You have been saved successfuly!')
          }
        }).catch((error) => {
        this.isLoading = false
        if (error.response.data.errors) {
          this.setErrors(error.response.data.errors)
        }
      })

    }
  }
}
</script>
