<template>
  <div class="row">
    <div class="col-md-10 offset-md-1">
      <div class="kt-simple-page__content-wrap">
        <h1 class="kt-align-center kt-margin-b-30">Yieldnodes NFT Terms and Conditions</h1>

        <h3>I. Introduction</h3>
        <p>Thank you for choosing to accept the YieldNodes NFT (“Yieldnodes NFT”). By accepting
          the YieldNodes NFT, you are confirming that you have read carefully and understood these
          terms and conditions and their importance in their entirety.</p>
        <h3>II. Definitions</h3>
        <p>For the purposes of these terms and conditions which shall govern the Transaction, the
          following terms, unless the context otherwise dictates, shall have the meanings set forth
          hereunder –</p>
        <div class="kt-padding-l-30">
          <p>“You” shall mean a person or entity expressing an interest in concluding the
            Transaction.</p>
          <p>“Company”, “we”, “us” or “our” means Exceptional Media Limited having its
            registered office at Suite 1905, 19/F, Tower Two Lippo Centre, 89 Queensway,
            Admiralty, Hong Kong.</p>
          <p>“Data” means all relevant information that may be required by the Company at its
            discretion to enable it to process and conclude the Transaction, and includes Personal
            Data.</p>
          <p>“Personal Data” means any information relating to an identified or identifiable
            natural person, which may be deemed as “personal data” in terms of the relevant
            provisions of the Personal Data (Privacy) Ordinance (Cap. 486 of the Laws of Hong
            Kong) and, if relevant, the provisions of the General Data Protection
            Regulation ((EU) 2016/679) (GDPR).</p>
          <p>“Yieldnodes Account” means an account opened at https://yieldnodes.com.</p>
          <p>“Yieldnodes NFT” means each non-fungible token to be issued by the Company for
            the purpose of the Transaction, representing the unique, specific and particular
            Yieldnodes Account against which each such Yieldnodes NFT is issued, subject to the
            terms and conditions herein.</p>
          <p>“Transaction” means the issuance and repurchase of the Yieldnodes NFT subject to
            the terms and conditions hereunder.</p>
          <p>“Website” shall mean https://yieldnodes.com and its subdomains or any alternative
            website offered by the Company.</p>
        </div>
        <h3>III. Specific Terms and Conditions Governing the Transaction</h3>
        <p><b>3.1</b> Subject to the terms and conditions hereunder, we agree to issue to you and you agree
          to accept from us the Yieldnodes NFT. The issue price of your Yieldnodes NFT shall
          be the entirety of the amount standing to the credit of your Yieldnodes account
          immediately prior to issuance (“Issue Price”). You hereby irrevocably authorize us
          to debit the entire balance from your Yieldnodes Account as payment of the Issue
          Price.</p>
        <p><b>3.2</b> We hereby agree to repurchase the Yieldnodes NFT on the following terms:</p>
        <div class="kt-padding-l-30">
          <p>(a) Date of repurchase: The date of repurchase shall be one (1) year from the date of
            issuance of the Yieldnodes NFT, provided that we may at our sole discretion (i)
            extend the date of repurchase by one (1) year or (ii) bring forward the date of
            repurchase to a date being not less than thirty (30) days from our notice in writing
            to you informing you of such revised date of repurchase (“Date of Repurchase”).</p>
          <p>(b) Repurchase price: 120% of the Issue Price of the Yieldnodes NFT assuming we
            repurchase the Yieldnodes NFT one (1) year from the date of issuance, provided
            that the repurchase price shall be adjusted pro rata if the Date of Repurchase is
            extended or brought forward by us in accordance with paragraph 3.2(a) above
            (“Repurchase Price”).</p>
        </div>
        <p>The Repurchase Price shall be credited to the Yieldnodes Account of the holder of the
          Yieldnodes NFT within seven (7) business days from the Date of Repurchase in Euros
          or such other currency as shall be determined by us at our sole discretion. For the
          avoidance of doubt, our obligation to repurchase the Yieldnodes NFT hereunder is
          subject to the condition that the holder of the Yieldnodes NFT has a valid Yieldnodes
          Account at the time we credit the Repurchase Price into the relevant Yieldnodes
          Account as at the Date of Repurchase, and such obligation shall automatically and
          irrevocably lapse in the event that no valid Yieldnodes Account exists on the Date of
          Repurchase.</p>
        <p><b>3.3</b> You should note that NFTs by their very nature may put you partly or wholly at risk
          since they carry a higher risk than traditional instruments and that any decision to
          carry out the Transaction should only be taken after careful consideration and
          consultation with your independent legal advisers and/or accountants and/or other
          financial advisers as to legal, tax, investment or any other matters related both directly
          and indirectly thereto. You confirm, acknowledge and agree that (a) you are entering
          into the Transaction after having taken independent advice as aforesaid, and having
          fully considered the risks of the Transaction; and (b) we have not solicited your
          participation in the Transaction nor have we given You any advice or
          recommendation in relation thereto.</p>
        <p><b>3.4</b> You agree and acknowledge that accepting the opportunity to participate in the
          Transaction, as well as any engagement between You and us in furtherance thereto,
          does not constitute an offer of securities, a solicitation to invest, or any warranty of
          future performance; Nor do we give any representation, warranty or guarantee
          regarding the potential risks involved in concluding the Transaction, the safety of
          such and/or the ability to trade the YieldNodes NFT and/or offer it as security.</p>
        <p><b>3.5</b> You shall carry exclusive responsibility for checking and verifying all matters prior to
          concluding the Transaction, and it is presumed that such decisions shall be taken by
          individuals or entities having significant experience with its subject-matter and an
          understanding of the usages and intricacies associated therewith.</p>
        <p><b>3.6</b> The contents of the Website or any other website directly or indirectly linked to the
          subject-matter of the Transaction, and any marketing and/or promotional materials do
          not form part of these terms and conditions and accordingly, no reliance ought to be
          made by any You on any information or data contained in such websites or messages.</p>
        <p><b>3.7</b> Notwithstanding any representations made at any time by us, it is understood that the
          value of the Yieldnodes NFT can go up or down and that representations made are not
          necessarily indicative of future performance and that the following further risks exit:</p>
        <div class="kt-padding-l-30">
          <ul>
            <li>Degradation and/or failure of virtual markets;</li>
            <li>Discontinuation of trading in any particular jurisdiction;</li>
            <li>the wide variety of regulations and standards and extensive laws the
              Applicant, including but not limited to fiscal legislation which may vary from
              one jurisdiction to another;</li>
            <li>Risks inherent to the largely unregulated cryptocurrency sector and the Virtual
              Market and any future regulation by government authorities, political
              developments, tax laws, alternative investments and other factors might have a
              direct or indirect impact on the value of the Yieldnodes NFT</li>
            <li>Reputational risk: this is usually associated with regulatory compliance,
              professional behavior of the human resources, reputation and financial
              soundness. Reputational risk could materially and adversely affect the
              Yieldnodes NFT</li>
            <li>Litigation risk: All industries are subject to legal claims, with and without
              merit. Defense and settlement costs can be substantial, even with respect to
              claims that have no merit. Due to the inherent uncertainty of the litigation and
              dispute resolution process, there can be no assurance that the resolution of any
              particular legal proceeding or dispute will not have a material adverse effect
              on the value of Assets and market health.</li>
            <li>The Yieldnodes NFT are susceptible to adverse economic developments and
              trends across jurisdictions.</li>
          </ul>
        </div>
        <h3>IV. Data</h3>
        <p><b>4.1</b> In order to carry out all processes leading up to the Transaction, the Company shall
          require Data from time to time. The Company may also require Personal Data for the
          following reasons:</p>
        <div class="table-responsive">
          <table class="table table-condensed table-bordered">
            <thead>
            <tr>
              <th>Purpose/Activity</th>
              <th>Type of data</th>
              <th>Lawful basis for processing including basis of legitimate interest</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>To carry out and complete the Transaction and manage our relationship with You</td>
              <td>(a) Identity<br>
                (b) Contact<br>
                (c) Postal and Email
                address<br>
                (d) Details of Corporate
                Entity<br>
                (c) Financial
                information<br>
                (d) Compliance data<br>
                (e) Details of transaction</td>
              <td>(a) To carry out the Transaction<br>
                (b) Compliance with a legal or regulatory
                obligation<br>
                (c) Necessary for our legitimate interests (to keep our
                records updated)</td>
            </tr>
            <tr>
              <td>To respond to
                your queries and
                additional
                requests</td>
              <td>(a) Identity<br>
                (b) Contact<br>
                (c) Information relating
                to your query</td>
              <td>(a) Performance of services relating to Transaction
                and ancillary matters</td>
            </tr>
            </tbody>
          </table>
        </div>
        <p><b>4.2</b> Access to the above Data is necessary for the purposes of the Transaction and You
          agree not to object to the use of Data or to withdraw or vary your consent, save where
          such right is afforded by mandatory requirements of law, with it being acknowledged
          and agreed in any case that, in the event of an allowed withdrawal or variation, this
          may lead to the Company not being in a position to complete the Transaction, in
          which event the Company’s obligation to repurchase shall irrevocably lapse.</p>
        <p><b>4.3</b> Where we need to collect Data, whether by law or where requested by the Company
          at its discretion, and you fail to provide that Data when requested, we may not be able
          to complete the Transaction. Any risks associated with non-submission of Data shall
          reside with you.</p>
        <p><b>4.4</b> The Company may disclose, transfer or otherwise give access the Data to its affiliated
          entities for the purpose of the Transaction and any ancillary matters.</p>
        <p><b>4.5</b> The Company may disclose, transfer or otherwise give access to the Data to third
          party service providers, legal counsel, consultants and other persons or entities
          engaged by the Company to provide services relating to the consideration by the
          Company of data being analysed for the purposes of concluding the Transaction,
          provided that such third party service providers have subscribed to the Company’s
          obligations as contained herein.</p>
        <p><b>4.6</b> You hereby consent to our privacy policy which may be accessed on our Website.</p>
        <h3>V. Intellectual Property Rights</h3>
        <p><b>5.1</b> All proprietary information (“Proprietary Information”), including but not limited to
          service marks, logos, graphics, business plans, mood boards, artist impressions,
          designs, calculations, documents and business information and plans provided by you
          shall remain the exclusive property of their owners and shall not be used by You.</p>
        <h3>VI. Warranties and Liability</h3>
        <p><b>6.1</b> To the maximum extent permitted by applicable laws, the NFT is provided “as is”
          and “as available” and excludes warranties of any kind, including, without limitation,
          any warranty for information, data, uptime or uninterrupted access, any warranties
          concerning the availability, correctness, accuracy, reliability, usefulness, or content
          of information, and any merchantability or fitness for a particular purpose, and we
          hereby disclaim any and all such warranties, express and implied. We do not warrant
          the NFT or the content, functions or materials contained therein will be timely,
          secure, uninterrupted, free from viruses or harmful elements, or error free, or that
          defects or malfunctions will be corrected. We make no warranty that the NFT will
          meet Your expectation or requirements. No advice, results, or information, whether
          oral or written, obtained by You from Us or in relation to the NFT shall create any
          warranty not expressly made herein.</p>
        <p><b>6.2</b> To the maximum extent permitted by applicable law, we will not be liable to You for
          any loss or damage, whether in contract, tort, negligence, breach or statutory duty, or
          otherwise and howsoever arising, even if foreseeable, arising under or in connection
          with the use of, or inability to use, the NFT, or the transfer, attempted transfer, or
          inability to transfer the NFT, including but not limited to any damages or claims
          arising from (i) Your error, such as forgotten passwords or access codes (including
          lost private keys), or incorrectly construed transactions (including mistyped
          addresses); (ii) server failure or data loss, or changes to the protocol rules for any
          relevant blockchain network (including forks and upgrades); (iii) corrupted account
          files or errors in the smart contract that mints and controls the NFT; (iv)
          unauthorized access or activities of third parties, including but not limited to the use
          of viruses, phishing or other means of attack against any app, network, or the
          electronic account (including sybil attacks, 51% attacks, brute forcing and other
          means of attack) or (v) security weakness, fraud, counterfeiting, blockchain
          malfunctions, and other technological errors or failures. The limitations set out in this
          clause will not limit or exclude liability which cannot be legally limited or excluded.</p>
        <p><b>6.3</b> We are not responsible for losses due to blockchains or any other features of the
          Polygon network or other third party services functioning and/or performance,
          including any late report by developers or representatives (or no report at all) of any
          issues with the blockchain supporting the Polygon network, including forks,
          technical node issues, or any other issues having fund losses as a result. You
          acknowledge and agree that such technologies are novel, experimental, and
          speculative and that therefore there is significant uncertainty regarding the operation
          and effects and risks thereof and the application of existing laws thereto.</p>
        <p><b>6.4</b> We have no control over any third party services. You acknowledge and agree that
          We are not liable for any loss or damage which may be incurred as a result of the
          availability or unavailability of third party services, or as a result of any reliance
          placed by You upon the protection or storage of any data You provide to those third
          party services, or upon the completeness, accuracy or existence of any advertising,
          products or materials on, or made available from, any third party services. You must
          read and comply at all times with the terms of service and any other terms applicable
          to such third party services.</p>
        <p><b>6.5</b> We shall not be liable for any damage caused to third parties as a result of any use
          You may make of the NFT outside its intended purpose.</p>
        <p><b>6.6</b> We will not be responsible for any failure to carry out any action under these terms
          and conditions as a result of circumstances beyond our reasonable control, including
          fire, flood, natural disasters, armed conflict, terrorist attack, labour matters,
          pandemic, technology or telecommunications failures, cyberattacks, or any
          unauthorised third party activities.</p>
        <h3>VII. Indemnity</h3>
        <p><b>7.1</b> You will defend, indemnify, and hold us, including each our officers, directors,
          employees, and agents harmless from any claims, actions, suits, losses, liabilities and
          expenses (including reasonable legal adviser fees) relating to or arising out of Your
          use or transfer of the NFT and Your participation in such use or transfer, including:
          (i) Your breach or alleged breach of these terms and conditions, or any applicable
          law, regulation, or binding rule or order of any applicable governmental or regulatory
          authority in connection with your use or transfer of the NFT; (ii) Your violation of
          the rights of any third party as a result of Your own interaction with such third party
          or any actions (or inactions) performed by you otherwise than as prescribed by these
          terms and conditions (including any attempted or actual transfer for the NFT to any
          third party); or (iii) any allegation that any materials that You submit to Us infringe
          or otherwise violate the copyright, trade mark, trade secret or other intellectual
          property rights or other rights of any third party. This indemnity shall be applicable
          without regard to the negligence of any party, including any indemnified person. You
          will cooperate as fully as required by us in the defence of any claim or loss, and we
          shall retain the exclusive right to defend, settle, compromise, and pay any such claim
          or loss.</p>
        <h3>VIII. Governing Law</h3>
        <p><b>8.1</b> These terms and conditions shall be governed by and construed in accordance with
          the laws of Hong Kong.</p>
        <p><b>8.2</b> Any dispute, controversy, difference or claim arising out of or relating to this contract,
          including the existence, validity, interpretation, performance, breach or termination
          thereof or any dispute regarding non- contractual obligations arising out of or relating
          to it shall be referred to and finally resolved by arbitration administered by the Hong
          Kong International Arbitration Centre (HKIAC) under the HKIAC Administered
          Arbitration Rules in force when the Notice of Arbitration is submitted.</p>
        <p><b>8.3</b> The law of this arbitration clause shall be Hong Kong law.</p>
        <p><b>8.4</b> The seat of arbitration shall be Hong Kong.</p>
        <p><b>8.5</b> The number of arbitrators shall be three. The arbitration proceedings shall be
          conducted in English.</p>

        <div class="kt-align-center kt-font-bold kt-margin-t-30"><a href="/">Back To Homepage</a></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TermsAndConditions',
  components: {},
  data () {
    return {}
  }
}
</script>
