<template>
  <router-view :key="$route.fullPath" />
</template>

<script>
  export default {
    name: 'Base',
    components: {},
    data() {
      return {}
    },
    methods: {}
  }
</script>
