<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="kt-simple-page__content-wrap">
        <Loader :loading="isLoading">
          <div class="row">
            <div class="col-md-10 offset-md-1">
              <div class="kt-portlet">
                <div class="kt-portlet__head header">
                  <div class="logo">
                    <img src="~images/new/logos/logo-yieldnodes-md-bg.png" alt="YieldNodes">
                  </div>

                  <h3 class="text-center">Total Coins owned by YieldNodes & Participators:</h3>
                  <h1 class="text-yellow text-bold text-center my-4">{{ totalCoins }}</h1>
                  <div class="text-center mb-3">updated: {{ date }} (beta version)</div>
                </div>
                <div class="kt-portlet__body">
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered table-hover" id="audit_table">
                      <thead>
                      <tr>
                        <th nowrap>Coin</th>
                        <th nowrap>Masternoded Balance</th>
                        <th nowrap>Masternodes Running</th>
                        <th nowrap>Staking Balance</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="coin in coins">
                        <td class="text-bold">{{ coin.currency }}</td>
                        <td>{{ coin.balance_mns_display }}</td>
                        <td>{{ coin.mns }}</td>
                        <td>{{ coin.balance_staking_display }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Loader>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/global/Loader.vue";

export default {
  name: 'CoinsAuditPage',
  components: {
    Loader
  },
  data () {
    return {
      coins: [],
      totalCoins: 0,
      totalMns: 0,
      date: '',
      isLoading: false
    }
  },
  created () {
    this.getData()
  },
  methods: {
    getData () {
      this.isLoading = true;
      axios.get(this.$api.audits).then(response => {
        this.isLoading = false;
        if(response.data) {
          this.coins = response.data.data;
          this.totalCoins = response.data.total_balance;
          this.totalMns = response.data.total_mns;
          this.date = response.data.date;
        }
      }).catch(error => {
        this.$store.dispatch('showErrorNotification', this.$t('general.unknown_error'))
        this.isLoading = true;
      })
    }
  }

}
</script>

<style scoped>
.text-black {
  color: #000000;
}
.text-yellow {
  color: #ffb000;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.1);
}
.text-bold {
  font-weight: bold;
}
.header {
  display: flex;
  flex-direction: column;
  background-color: #114b70;
  color: #fff;

  .logo {
    margin: 25px auto;
    text-align: center;
  }
}
</style>
