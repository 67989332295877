<template>
  <div class="row">
    <div class="col-md-10 offset-md-1">
      <div class="kt-simple-page__content-wrap">
        <July2022 />
      </div>
    </div>
  </div>
</template>

<script>
import July2022 from './audit/July2022';

export default {
  name: 'PrivacyPolicy',
  components: {
    July2022
  },
  data () {
    return {}
  }
}
</script>
